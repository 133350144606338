<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-toolbar dark color="primary">
      <div class="mt-2 mr-3">
        <v-switch
          class="mt-4"
          label="Without date"
          inset
          v-model="nosurgeryD"
        ></v-switch>
      </div>

      <v-autocomplete
        class="mx-1"
        :label="'Filter by ' + getpatientN"
        :items="patients"
        v-model="filterRequest.patientUuid"
        item-text="fullname"
        item-value="uuid"
        style="max-width: 300px"
        prepend-inner-icon="mdi-account-search"
        clearable
        @change="getSummary"
        hide-details
        outlined
        dense
      >
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item.fullname"
              ></v-list-item-title>
              <!-- <v-list-item-subtitle
                v-html="data.item.rol"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-html="data.item.phone"
              ></v-list-item-subtitle> -->
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <v-autocomplete
        class="mx-1"
        label="Filter by Employees"
        :items="workers"
        v-model="filterRequest.employeeUuid"
        item-text="fullname"
        item-value="uuid"
        style="max-width: 300px"
        prepend-inner-icon="mdi-account-search"
        clearable
        @change="getSummary"
        hide-details
        outlined
        dense
      >
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item.fullname"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.rol"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-html="data.item.phone"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
      <v-layout class="ma-4">
        <v-row class="mt-3" no-gutters>
          <v-col class="mt-3">
            <ma-date-picker
              :datepickerProps="{
                'no-title': true,
              }"
              v-model="dateFrom"
              label="From"
            />
          </v-col>
          <v-col class="ml-2 mt-3">
            <ma-date-picker
              :datepickerProps="{
                'no-title': true,
              }"
              v-model="dateTo"
              label="To"
            />
          </v-col>
        </v-row>
      </v-layout>
      <template v-if="validClean">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="ml-2"
              elevation="0"
              small
              rounded
              depressed
              fab
              v-on="on"
              v-bind="attrs"
              @click="resetFilter()"
            >
              <v-icon>mdi-backup-restore</v-icon>
            </v-btn>
          </template>
          <span>Clear Dates</span>
        </v-tooltip>
      </template>
    </v-toolbar>
    {{/* Interval indicator */}}
    <v-layout class="pa-4" row>
      <v-layout column>
        <div class="mr-2 text-capitalize">
          {{ typeDate.replace("-", " ") }}
        </div>
        <v-layout column class="text-body-1 text--secondary">
          <div>
            From:
            <span class="text-caption">{{
              prettyDateShow(filterRequest.dateRange.date1)
            }}</span>
          </div>
          <div>
            To:
            <span class="text-caption">{{
              prettyDateShow(filterRequest.dateRange.date2)
            }}</span>
          </div>
        </v-layout>
      </v-layout></v-layout
    >
    <v-divider class="my-2"></v-divider>
    <v-data-table
      dense
      :headers="headers"
      :items="summarys"
      :server-items-length="totalSummarys"
      :options.sync="options"
      item-key="index"
      class="elevation-1"
      :loading="loading"
      @click:row="goToPatient"
    >
      <template v-slot:[`item.coordinator`]="{ item }">
        <template v-if="item.coordinator">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                rounded
                small
                v-on="on"
                :color="getColor(getName(item.coordinator))"
                class="text-capitalize btn_name"
              >
                {{ getName(item.coordinator) }}</v-btn
              >
            </template>
            <span>{{ item.coordinator }}</span>
          </v-tooltip>
        </template>
      </template>
      <template v-slot:[`item.createAt`]="{ item }">
        {{ item.surgicaldate ? getDOS(item.surgicaldate) : "-" }}
      </template>
      <template v-slot:[`item.notes`]="{ item }">
        {{ item.notes ? item.notes : "-" }}
      </template>
      <template v-slot:[`item.amountdue`]="{ item }">
        {{ item.amountdue | currency }}
      </template>
      <template v-slot:[`item.patient`]="{ item }">
        <span class="text-capitalize">{{ item.patient }}</span>
      </template>
      <template v-slot:[`item.procedures`]="{ item }">
        <template v-if="item.procedures"
          ><div class="procedures">
            <v-btn
              v-for="its in item.procedures"
              :key="its.id"
              rounded
              small
              :color="its.color"
              class="text-capitalize btn_name"
              @click="goToPatientprocedure(item, its)"
            >
              {{ getNameProcedure(its) }}</v-btn
            >
          </div></template
        >
        <template v-else>-</template>
      </template>

      <template v-slot:[`item.labs`]="{ item }">
        <div class="d-flex">
          <template v-if="item.labsCkeck.a1c">
            <v-btn
              rounded
              small
              :color="getColor(getName('a1c'))"
              class="text-capitalize btn_name"
            >
              a1c</v-btn
            >
          </template>
          <template v-if="item.labsCkeck.chem">
            <v-btn
              rounded
              small
              :color="getColor(getName('chem'))"
              class="text-capitalize btn_name"
            >
              Chem</v-btn
            >
          </template>
          <template v-if="item.labsCkeck.ekg">
            <v-btn
              rounded
              small
              :color="getColor(getName('ekg'))"
              class="text-capitalize btn_name"
            >
              ekg</v-btn
            >
          </template>
          <template v-if="item.labsCkeck.hcg">
            <v-btn
              rounded
              small
              :color="getColor(getName('hcg'))"
              class="text-capitalize btn_name"
            >
              hcg</v-btn
            >
          </template>
          <template v-if="item.labsCkeck.hiv">
            <v-btn
              rounded
              small
              :color="getColor(getName('hiv'))"
              class="text-capitalize btn_name"
            >
              hiv</v-btn
            >
          </template>
          <template v-if="item.labsCkeck.man_us">
            <v-btn
              rounded
              small
              :color="getColor(getName('man'))"
              class="text-capitalize btn_name"
            >
              MAM/US</v-btn
            >
          </template>
          <template v-if="item.labsCkeck.mc">
            <v-btn
              rounded
              small
              :color="getColor(getName('mc'))"
              class="text-capitalize btn_name"
            >
              mc</v-btn
            >
          </template>
          <template v-if="item.labsCkeck.pt">
            <v-btn
              rounded
              small
              :color="getColor(getName('pt'))"
              class="text-capitalize btn_name"
            >
              pt</v-btn
            >
          </template>
          <template v-if="item.labsCkeck.pt_ptt_inr">
            <v-btn
              rounded
              small
              :color="getColor(getName('pt_ptt_inr'))"
              class="text-capitalize btn_name"
            >
              PT/PTT/INR</v-btn
            >
          </template>
          <template v-if="item.labsCkeck.ua">
            <v-btn
              rounded
              small
              :color="getColor(getName('ua'))"
              class="text-capitalize btn_name"
            >
              ua</v-btn
            >
          </template>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <template v-if="item.procedures">
          <div class="procedures">
            <div v-for="its in item.procedures" :key="its.id">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    rounded
                    small
                    :color="its.color"
                    class="btn_name"
                    @click="goToPatientprocedure(item, its)"
                  >
                    {{ its.dasboardStatus }}</v-btn
                  >
                </template>
                <template v-if="its.surgery">
                  <span>{{ "Procedure: " + its.surgery.name }}</span>
                </template>
                <template v-if="its.treatment">
                  <span>{{ "Procedure: " + its.treatment.name }}</span>
                </template>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template v-else> - </template>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { utilMixin } from "@/utils/mixins";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";

export default {
  name: "pps-sales",
  mixins: [utilMixin],
  components: { MaDatePicker },
  data: () => ({
    loading: false,
    options: {},
    patients: [],
    summarys: [],
    totalSummarys: 0,
    typeDate: "this-week",
    dateFrom: "",
    dateTo: "",
    nosurgeryD: false,
    filterRequest: {
      noSurgeryDate: false,
      offset: 0,
      limit: 100,
      employeeUuid: null,
      patientUuid: null,
      procedure_type: null,
      dateRange: {
        date1: null,
        date2: null,
      },
    },
  }),
  watch: {
    options: {
      handler() {
        this.getPatients();
        this.getSummary();
      },
      deep: true,
    },

    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.typeDate = "custom-select";
        this.getSummary();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.typeDate = "custom-select";
        this.getSummary();
      }
    },
    nosurgeryD(val) {
      this.filterRequest.noSurgeryDate = val;
      this.getSummary();
    },
  },
  computed: {
    ...mapGetters(["getCoordinatorN", "getpatientN"]),
    ...mapState("crmEmployeeModule", ["workers"]),
    headers() {
      return [
        {
          text: "A " + this.getpatientN,
          color: "pink",
          sortable: false,
          width: 250,
          value: "patient",
        },
        { text: "DOS", value: "createAt", sortable: false, align: "center" },
        {
          text: this.getCoordinatorN,
          width: 100,
          value: "coordinator",
          sortable: false,
        },
        { text: "Pre-Op Status", value: "status", sortable: false, width: 300 },
        { text: "Balance", value: "amountdue", sortable: false },
        { text: "Procedure", width: 300, value: "procedures", sortable: false },
        { text: "Labs", value: "labs", sortable: false },
      ];
    },
    validClean() {
      if (
        (this.dateFrom != null &&
          this.dateFrom != undefined &&
          this.dateFrom != "") ||
        (this.dateTo != null && this.dateTo != undefined && this.dateTo != "")
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmMedicFormModule", ["actListPatients"]),
    ...mapMutations("crmDashboardModule", ["mutprocedureSelectid"]),
    getName(fullname) {
      const name = fullname.split(" ")[0];
      return name;
    },
    goToPatient(item) {
      this.$router.push("/patients/details/" + item.pat_uuid);
    },
    goToPatientprocedure(item, procedure) {
      this.mutprocedureSelectid(procedure.id);
      this.$router.push("/patients/details/" + item.pat_uuid);
    },

    async getPatients() {
      this.loading = true;
      this.patients = (await getAPI("/patient/listAllPatients")).data;
      this.loading = false;
    },

    getSummary() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 15;
      this.filterRequest.limit = itmper;
      this.filterRequest.offset = (page - 1) * itmper;

      if (this.typeDate == "custom-select") {
        const init = moment(this.dateFrom).startOf("day").utc().toISOString();
        const end = moment(this.dateTo).endOf("day").utc().toISOString();
        this.filterRequest.dateRange = { date1: init, date2: end };
      } else {
        const init = moment().startOf("week").utc().toISOString();
        const end = moment().endOf("week").utc().toISOString();
        this.filterRequest.dateRange = { date1: init, date2: end };
      }
      let body = Object.assign({}, this.filterRequest);
      body = this.cleanNull(body);

      getAPI.post("/sprocedures/getDashboardSummary", body).then((res) => {
        this.totalSummarys = res.data.count;
        this.summarys = res.data.result;
        const temp = this.summarys.map((sum) => {
          const temprocedures = sum.procedures.map((proc) => {
            const color = this.getRandomColor(proc.id);

            return { ...proc, color: color };
          });
          return { ...sum, procedures: temprocedures };
        });

        this.summarys = temp;
      });
    },

    getDOS(date) {
      if (date == null || date == undefined) {
        return "-";
      }
      const dat = date.split("T")[0].toString();
      const d = dat.split("-")[2];
      const m = dat.split("-")[1];
      const a = dat.split("-")[0];

      return d + "/" + m + "/" + a;
    },
    getStatus(procedures) {
      if (procedures.length != 0) {
        const p = procedures[0].dasboardStatus;
        return p ? p : "-";
      }
      return "-";
    },
    getNameProcedure(procedure) {
      if (procedure.surgery) {
        return procedure.surgery.name;
      }
      return procedure.treatment.name;
    },
    getColorProcedure(id) {
      const proc = this.summarys.find((sum) => sum.id == id);
      return proc.color;
    },
    resetFilter() {
      this.typeDate = "this-week";
      this.dateFrom = "";
      this.dateTo = "";
      this.getSummary();
    },
  },
  mounted() {
    this.actGetEmployees();
  },
};
</script>
<style scoped>
.btn_name {
  height: 20px !important;
  margin: 0 1px;
}
.procedures {
  display: flex;
  flex-wrap: wrap;
}
</style>
