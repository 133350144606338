











































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";
export default Vue.extend({
  name: "procedure-card",
  props: {
    id: Number,
    type: String,
    patientName: String,
    patientUuid: String,
    surgicalDate: String,
    subtype: String,
    total: [String, Number],
    deb: [String, Number],
    createAt: String,
  },
  computed: {
    ...mapState("crmDashboardModule", ["procedureSelectid"]),
    ...mapGetters(["getpatientN"]),
  },
  methods: {
    ...mapMutations("crmDashboardModule", ["mutprocedureSelectid"]),
    details() {
      this.mutprocedureSelectid(this.id);

      this.$router.push("/patients/details/" + this.patientUuid);
    },
  },
});
