interface DropResult {
  // Old position
  removedIndex: number;
  // New position
  addedIndex: number;
  // Data to set
  payload: any;
}

/**
 *
 * @param arr The list to apply the changes
 * @param dragResult The payload with info about movements
 * @returns any
 */
const applyDrag = (arr: any[], dragResult: DropResult): any[] => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export { DropResult, applyDrag };
